<template>
    <div
      :class="$style.root"
    >
      <Transition name="mask">
        <NavigationMenuComponent
          v-show="menuOpen"
          :isOpen="menuOpen"
          @onToggleMenu="menuOpen = !menuOpen"
          @onCloseMenu="menuOpen = false"
          @onToggleSearch="searchOpen = !searchOpen"
        />
      </Transition>

      <Transition name="mask">
        <SearchComponent
          v-show="searchOpen"
          :isOpen="searchOpen"
          @closeSelf="searchOpen = false"
        />
      </Transition>


      <CoreSvgBackgroundComponent>
        <NavigationSiteHeaderComponent
          @onToggleMenu="menuOpen = !menuOpen"
          @onToggleSearch="searchOpen = !searchOpen"
          ref="siteHeaderTarget"
        />

        <main :class="$style.main">
          <slot />
        </main>

        <SharedFooterComponent v-once />
      </CoreSvgBackgroundComponent>
      <SharedGtagConsentComponent v-once />
    </div>
</template>

<script setup>
  const menuOpen = ref(false)
  const searchOpen = ref(false)

  const el = ref(null)
  const { height } = useElementSize(el)

  const siteHeaderTarget = ref(null)
  const { height: siteHeaderHeight } = useElementSize(siteHeaderTarget.value?.$el)
</script>

<style module>
.root {
  composes: font-default from global;

  display: flex;
  flex-direction: column;

  min-height: var(--100vh);

  position: relative;
}

.grid {
  margin-top: var(--unit--default);
}

.main {
  flex: 1;

  display: flex;
  flex-direction: column;

  margin-top: calc( var(--unit--vertical) * 2 + var(--font-default--line-height));
}
</style>
