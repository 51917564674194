<template>
  <div
    :class="$style.root"
    ref="rootElement"
  >
    <slot  />

    <ClientOnly>
      <CoreSvgBackgroundItemComponent
        v-for="(svgIndex, index) in svgIndexes"
        :key="`${svgIndex}-${index}`"
        :svgIndex="svgIndex"
        :slideType="slideTypes?.length ? slideTypes[index] : null"
        :data-color-scheme="routeName"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { range, sampleSize } from 'lodash-es'

const mainStore = useMainStore()

const route = useRoute()
const nuxtApp = useNuxtApp()

const rootElement = ref(null)
const { height } = useElementSize(rootElement)

const routeName = computed(() => nuxtApp.$getRouteBaseName())

const svgCount = ref(16)
const svgIndexes = ref([2])

const slideTypes = computed(() => mainStore?.coreBackgroundSvgSlideTypes)

const initSelf = () => {
  const options = range(1, svgCount.value)
  const amount = route?.name?.includes('index') ? slideTypes?.value?.length : 1
  svgIndexes.value = sampleSize(options, amount)
}

initSelf()

const fromName = ref(null)
nuxtApp.hook('page:finish', () => {
  const toName = nuxtApp.$getRouteBaseName()
  if(nuxtApp.$getRouteBaseName() !== 'organisation' && toName !== fromName.value) {
    initSelf()
    fromName.value = toName
  }
})
</script>


<style module>
.root {
  width: 100vw;

  position: relative;
  overflow: hidden;

  min-height: var(--100vh);

  display: flex;
  flex-direction: column;
}
</style>
